<template>
  <b-sidebar
    id="main-side-menu"
    tag="nav"
    bg-variant="blue-dark-500"
    text-variant="white"
    width="220px"
    no-header
    :backdrop="!isLargeScreen"
    no-close-on-esc
    :no-close-on-route-change="isLargeScreen"
    :visible="isLargeScreen"
  >
    <b-list-group>
      <div v-if="!isLargeScreen">
        <b-list-group-item class="px-3 mt-3">
          <b-row class="no-gutters justify-content-between">
            <b-col class="col-auto" @click="goToPage('UserAccount')">
              <b-avatar
                class="mr-2 clickable"
                style="width: 26px;height: 26px;"
                :src="userAvatar"
              />
            </b-col>
            <b-col class="col-auto">
              <b-link class="text-uppercase text-s text-white" @click="logout">
                <small>{{ $t("button.logout") }}</small>
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>
        <div v-if="hasAdminFilter" class="px-3 mt-3">
          <menu-filter />
        </div>
      </div>
      <div v-for="(navGroup, i) in navGroupList" :key="i">
        <p class="h6 text-white my-3 py-0 px-3 text-uppercase">
          {{ $t(navGroup.categoryName) }}
        </p>
        <div v-for="(navItem, j) in navGroup.navList" :key="j">
          <b-list-group-item
            class="px-0 clickable"
            :class="{ active: isRouteActive(navItem.routeName) }"
            @click="goToPage(navItem.routeName)"
          >
            <b-row class="no-gutters align-items-end flex-nowrap px-3">
              <b-col class="col-auto mr-2">
                <svg
                  :viewBox="navItem.viewBox || '0 0 16 16'"
                  class="menu__icon"
                >
                  <path
                    v-for="(icon, k) in navItem.iconList"
                    :key="k"
                    fill-rule="evenodd"
                    :d="icon"
                  ></path>
                </svg>
              </b-col>
              <b-col class="meun__text__item">
                {{ $t(`sideMenu.${navItem.text}`) }}
              </b-col>
            </b-row>
          </b-list-group-item>
        </div>
      </div>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import { appMixin, authMixin, userMixin } from "./../../mixins";
import {
  dashboardMenuItem,
  maintainerIssuesMenuItem,
  issuesMenuItem,
  communicationsMenuItem,
  condominiumsMenuItem,
  maintainersMenuItem,
  groupsMenuItem,
  parametersMenuItem,
  domiUsersMenuItem,
  adminUsersMenuItem,
  consumerUsersMenuItem,
  maintainerUsersMenuItem,
  postsMenuItem,
  eventsMenuItem
} from "../../constants/menuItems";

import MenuFilter from "./MenuFilter";
export default {
  components: {
    MenuFilter
  },
  props: {
    isLargeScreen: {
      type: Boolean,
      default: false
    }
  },
  mixins: [appMixin, authMixin, userMixin],
  computed: {
    userAvatar() {
      return this.$_getUserProfilePhoto(this.$_authUser.profile);
    },
    navGroupList() {
      let list = [];
      if (this.$is1Board && this.$_authUserIsMaintainer) {
        list = [
          {
            categoryName: "",
            navList: [maintainerIssuesMenuItem]
          }
        ];
      } else if (this.$isDomi) list = this.domiAppMenu;
      else list = this.oneBoardAppMenu;
      console.log(this.$isDomi, this.$is1Board);

      return list;
    },
    domiAppMenu() {
      const list = [
        {
          categoryName: "",
          navList: [dashboardMenuItem, issuesMenuItem, communicationsMenuItem]
        },
        {
          categoryName: "sideMenu.settings",
          navList: [condominiumsMenuItem, maintainersMenuItem]
        }
      ];

      if (this.$_authUserIsDomi) {
        list.push({
          categoryName: "sideMenu.users",
          navList: [
            domiUsersMenuItem,
            adminUsersMenuItem,
            consumerUsersMenuItem,
            maintainerUsersMenuItem,
            postsMenuItem
          ]
        });
      }
      return list;
    },
    oneBoardAppMenu() {
      const list = [
        {
          categoryName: "",
          navList: [
            dashboardMenuItem,
            issuesMenuItem,
            communicationsMenuItem,
            postsMenuItem,
            eventsMenuItem
          ]
        },
        {
          categoryName: "sideMenu.settings",
          navList: [
            condominiumsMenuItem,
            groupsMenuItem,
            maintainerUsersMenuItem,
            parametersMenuItem
          ]
        }
      ];

      if (this.$_authUserIsDomi) {
        list.push({
          categoryName: "sideMenu.users",
          navList: [
            domiUsersMenuItem,
            adminUsersMenuItem,
            consumerUsersMenuItem
          ]
        });
      }

      return list;
    },
    hasAdminFilter() {
      const pages = [
        "Dashboard",
        "Issues",
        "Communications",
        "Condominiums",
        "Maintainers"
      ];

      if (this.$is1Board) {
        pages.push("MaintainerUsers");
        pages.push("Posts");
      }
      return this.$_authUserIsDomi && pages.includes(this.$route.name);
    }
  },
  methods: {
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
    goToPage(routeName) {
      if (!this.isRouteActive(routeName) && routeName !== "") {
        this.$router.push({ name: routeName });
      }
    },
    logout() {
      this.$store.dispatch("logout", null, { root: true });
    }
  }
};
</script>

<style lang="scss" scoped>
.list-group-item {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-top: 6px;
  padding-bottom: 6px;
  &:hover {
    background-color: var(--blue-dark-600);
  }
}
.list-group-item.active::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 0;
  height: 0;
  background: none;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-right: 18px solid var(--primary-bg-color);
}
.list-group-item.active {
  color: white;
  background-color: var(--orange-light);
}
.menu__icon {
  width: 18px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg)
    brightness(102%) contrast(101%);
}
.list-group-item.active /deep/ .menu__icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg)
    brightness(102%) contrast(101%);
}
.meun__text__item {
  font-size: 14px;
  font-weight: 400;
}
</style>
